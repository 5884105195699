function getDefaultScrollbarWidth() {
  var outer = document.createElement('div');
  outer.style.visibility = 'hidden';
  outer.style.width = '100px';
  outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps

  document.body.appendChild(outer);

  var widthNoScroll = outer.offsetWidth;
  // force scrollbars
  outer.style.overflow = 'scroll';

  // add innerdiv
  var inner = document.createElement('div');
  inner.style.width = '100%';
  outer.appendChild(inner);

  var widthWithScroll = inner.offsetWidth;

  // remove divs
  outer.parentNode.removeChild(outer);

  return widthNoScroll - widthWithScroll;
}

function setFullWidthForScrollBar() {
  var scrollbarWidth = getDefaultScrollbarWidth();

  var css =
      ':root { --scrollbar-width: ' +
      scrollbarWidth +
      'px; --page-width: calc(100vw - ' +
      scrollbarWidth +
      'px); } .full-blk { width: var(--page-width); margin-inline: calc(var(--page-width) / -2);}',
    head = document.head || document.getElementsByTagName('head')[0],
    style = document.createElement('style');

  style.type = 'text/css';
  if (style.styleSheet) {
    style.styleSheet.cssText = css;
  } else {
    style.appendChild(document.createTextNode(css));
  }

  head.appendChild(style);
}

document.addEventListener('DOMContentLoaded', function () {
  setFullWidthForScrollBar();
});
